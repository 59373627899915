import React, { useEffect } from 'react'
import GoogleMapPoint from '../../GoogleMapPoint'
import Form from '../../Form'
import CarsChargeTable from '../Common/CarsChargeTable'
import PopularOutstations from '../../PopularOutstations'

const Salemtovellore = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

  return (
<div>
<GoogleMapPoint origin={{lat:11.6643,lng:78.1460}} destination={{lat:12.9165,lng:79.1325}}/>

<div className="form_bg relative bg-fixed flex justify-center px-3">
<div className='bg-white w-full md:w-[80%] lg:w-1/2  rounded-lg px-4  pb-4 relative z-10 mt-4 md:mt-8 mb-4 md:mb-8' id='book_taxi_form'>
<Form />
</div>
</div>


<div className=" container px-3 sm:px-4 md:px-5 lg:px-10 text-[#082a5e] py-4">
<h3 className=" font-bold text-[26px] sm:text-[28px] md:text-[30px] uppercase">
THE ULTIMATE ROAD TRIP: salem to vellore EXCURSION WITH OUTSTATION TAXI
</h3>
<p className=" pt-2">
Efficient salem to vellore Taxi Service: Your Smooth Journey Companion

</p>

<h3 className=" font-bold text-[26px] sm:text-[28px] md:text-[30px] uppercase mt-5">
DISCOVERING THE OUTSTATION TAXI ADVANTAGE:
</h3>
<p className=" pt-2">
Are you planning a trip from salem to vellore and in need of a reliable taxi service?
 Look no further! Our taxi service provides an efficient and comfortable travel 
 solution for your journey between these two vibrant cities. 
 Here are the key features of our salem to vellore taxi service that make us 
 your ideal travel companion:

</p>

<h3 className=" font-bold text-[16px] md:text-[20px] uppercase mt-5">
Doorstep Pickup and Drop-off:
</h3>
<p className=" pt-2">
Punctuality is our priority. Our drivers will pick you up promptly at 
your specified location in salem and drop you off at your desired destination
 in vellore on time, without any delays

</p>

<h3 className=" font-bold text-[16px] md:text-[20px] uppercase mt-5">
Comfortable and Well-Maintained Vehicles:
</h3>
<p className=" pt-2">
Our fleet consists of comfortable and well-maintained cars, including sedans, 
SUVs, and luxury vehicles. You can choose the vehicle that suits your 
preferences and enjoy a comfortable ride throughout the journey

</p>

<h3 className=" font-bold text-[16px] md:text-[20px] uppercase mt-5">
PROFESSIONAL AND RELIABLE DRIVERS:
</h3>
<p className=" pt-2">
Our drivers are experienced, licensed, and familiar with the routes between
 salem and vellore. They prioritize your safety and ensure a smooth and 
 pleasant travel experience.

</p>

<h3 className=" font-bold text-[16px] md:text-[20px] uppercase mt-5">
Transparent Pricing:
</h3>
<p className=" pt-2">
We believe in transparent pricing with no hidden charges. 
You'll receive a fair and upfront fare estimate, allowing you to plan 
your travel budget effectively.

</p>

<div className=" my-4 sm:my-6 md:my-10">
<CarsChargeTable />
</div>

<h3 className=" font-bold text-[16px] md:text-[20px] uppercase mt-5">
24/7 Customer Support:
</h3>
<p className=" pt-2">
To cater to any unforeseen circumstances or address any queries or
concerns, Outstation Taxi provides round-the-clock customer support.
You can reach out to their dedicated team for assistance or guidance
at any time during your journey.
</p>
<h3 className=" font-bold text-[16px] md:text-[20px] uppercase mt-5">
EASY BOOKING
</h3>
<p className=" pt-2">
Booking our taxi service is convenient and hassle-free. You can book your ride online through our website or mobile app, or simply give us a call. Provide us with your travel details, and we'll take care of the rest

</p>

<h3 className=" font-bold text-[16px] md:text-[20px] uppercase mt-5">
The Enchanting Journey from salem to vellore:
</h3>
<p className=" pt-2">
Make your journey from salem to vellore a smooth and enjoyable experience by choosing our reliable taxi service. Sit back, relax, and enjoy the scenic route as we take you to your destination safely and comfortably.

</p>

<p className=" pt-5">
Contact us today to book your salem to vellore taxi and experience hassle-free 
travel with excellent service. We look forward to serving you and ensuring your
 trip is memorable for all the right reasons.

</p>
</div>
< PopularOutstations />

    </div>  )
}

export default Salemtovellore
