import React, { useEffect } from 'react'
import GoogleMapPoint from '../../GoogleMapPoint'
import Form from '../../Form'
import CarsChargeTable from '../Common/CarsChargeTable'
import PopularOutstations from '../../PopularOutstations'

const TrichytoBangalore = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

  return (
<div>
<GoogleMapPoint origin={{lat:10.7905,lng:78.7047}} destination={{lat:12.9716,lng:77.5946}}/>

<div className="form_bg relative bg-fixed flex justify-center px-3">
<div className='bg-white w-full md:w-[80%] lg:w-1/2  rounded-lg px-4  pb-4 relative z-10 mt-4 md:mt-8 mb-4 md:mb-8' id='book_taxi_form'>
<Form />
</div>
</div>


<div className=" container px-3 sm:px-4 md:px-5 lg:px-10 text-[#082a5e] py-4">
<h3 className=" font-bold text-[26px] sm:text-[28px] md:text-[30px] uppercase">
TRICHY TO BANGALORE TAXI SERVICE: SEAMLESS TRAVEL EXPERIENCE AT YOUR FINGERTIPS
</h3>
<p className=" pt-2">
Are you planning a journey from Trichy to Bangalore and seeking a comfortable and reliable taxi service? Your search ends here! Our taxi service offers a seamless travel experience tailored to make your trip between these two dynamic cities hassle-free and enjoyable.

</p>

<h3 className=" font-bold text-[26px] sm:text-[28px] md:text-[30px] uppercase mt-5">
DOORSTEP PICKUP AND DROP-OFF:
</h3>
<p className=" pt-2">
Punctuality is our commitment. Our drivers will arrive promptly at your specified location in Trichy and ensure you reach Bangalore on time, without any unnecessary delays.

</p>

<h3 className=" font-bold text-[16px] md:text-[20px] uppercase mt-5">
COMFORTABLE AND WELL-MAINTAINED VEHICLES:
</h3>
<p className=" pt-2">
Are you planning a journey from Trichy to Bangalore and seeking a comfortable and reliable taxi service? Your search ends here! Our taxi service offers a seamless travel experience tailored to make your trip between these two dynamic cities hassle-free and enjoyable.

</p>

<h3 className=" font-bold text-[16px] md:text-[20px] uppercase mt-5">
PROFESSIONAL AND RELIABLE DRIVERS:
</h3>
<p className=" pt-2">
Are you planning a journey from Trichy to Bangalore and seeking a comfortable and reliable taxi service? Your search ends here! Our taxi service offers a seamless travel experience tailored to make your trip between these two dynamic cities hassle-free and enjoyable.

</p>

<h3 className=" font-bold text-[16px] md:text-[20px] uppercase mt-5">
TRANSPARENT PRICING:
</h3>
<p className=" pt-2">
We believe in transparent pricing with no hidden charges. You'll receive a fair and upfront fare estimate before booking your ride, allowing you to plan your travel budget effectively

</p>

<h3 className=" font-bold text-[16px] md:text-[20px] uppercase mt-5">
CONVENIENT BOOKING:
</h3>
<p className=" pt-2">
Booking your Trichy to Bangalore taxi is easy. You can book through our website, mobile app, or by contacting our customer service team. Provide your travel details, and we'll handle the rest.

</p>

<div className=" my-4 sm:my-6 md:my-10">
<CarsChargeTable />
</div>

<h3 className=" font-bold text-[16px] md:text-[20px] uppercase mt-5">
24/7 CUSTOMER SUPPORT:
</h3>
<p className=" pt-2">
Whether you need a taxi during the day or night, our service operates 24/7 to accommodate your travel needs. You can rely on us for a comfortable and reliable ride at any time.

</p>

<h3 className=" font-bold text-[16px] md:text-[20px] uppercase mt-5">
THE ENCHANTING JOURNEY FROM TRICHY TO BANGALORE:
</h3>
<p className=" pt-2">
Make your journey from Trichy to Bangalore stress-free and enjoyable by choosing our reliable taxi service. Sit back, relax, and enjoy the scenic landscapes as we take you to your destination safely and comfortably.

</p>

<p className=" pt-5">
Contact us today to book your taxi and experience convenient travel with exceptional service. We look forward to serving you and ensuring your trip to Bangalore is memorable for all the right reasons.

</p>
</div>
< PopularOutstations />

    </div>  )
}

export default TrichytoBangalore
