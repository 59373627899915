import React, { useEffect } from 'react'
import GoogleMapPoint from '../../GoogleMapPoint'
import Form from '../../Form'
import CarsChargeTable from '../Common/CarsChargeTable'
import PopularOutstations from '../../PopularOutstations'

const CoimbatoretoTrichy = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

  return (
    <div>

< GoogleMapPoint origin={{lat:11.0168,lng:76.9558}} destination={{lat:10.7905,lng:78.7047}}/>

<div className="form_bg relative bg-fixed flex justify-center px-3">
<div className='bg-white w-full md:w-[80%] lg:w-1/2  rounded-lg px-4  pb-4 relative z-10 mt-4 md:mt-8 mb-4 md:mb-8' id='book_taxi_form'>
<Form />
{/* <p> Hii {originName && < GoogleMapPoint  origin={{lat:11.0168,lng:76.9558}} 
destination={{lat:10.7905,lng:78.7047}}/> }</p> */}

</div>
</div>


<div className=" container px-3 sm:px-4 md:px-5 lg:px-10 text-[#082a5e] py-4">
<h3 className=" font-bold text-[26px] sm:text-[28px] md:text-[30px] uppercase">
EFFICIENT COIMBATORE TO TRICHY TAXI SERVICE: YOUR RELIABLE TRAVEL COMPANION
</h3>
<p className=" pt-2">
Are you planning a trip from Coimbatore to Trichy and seeking a reliable taxi service? Look no further! Our taxi service provides an efficient and comfortable travel solution for your journey between these two dynamic cities.

</p>

<h3 className=" font-bold text-[26px] sm:text-[28px] md:text-[30px] uppercase mt-5">
DISCOVERING THE OUTSTATION TAXI ADVANTAGE:
</h3>
<p className=" pt-2">
Outstation Taxi is a leading car rental service in Tamil Nadu, offering an array of modern, well-maintained vehicles and experienced drivers. With their commitment to customer satisfaction, they have gained a reputation for providing seamless travel experiences. Here are some key advantages of choosing Outstation Taxi for your Coimbatore to Bangalore trip:

</p>

<h3 className=" font-bold text-[16px] md:text-[20px] uppercase mt-5">
DOORSTEP PICKUP AND DROP-OFF:
</h3>
<p className=" pt-2">
Punctuality is our priority. Our drivers will arrive promptly at your specified location in Coimbatore and ensure you reach your destination in Trichy on time, without unnecessary delays.

</p>

<h3 className=" font-bold text-[16px] md:text-[20px] uppercase mt-5">
COMFORTABLE AND WELL-MAINTAINED VEHICLES:
</h3>
<p className=" pt-2">
Our fleet includes comfortable and well-maintained cars, such as sedans, SUVs, and spacious vehicles suitable for groups. You can choose the vehicle that best suits your needs and enjoy a comfortable ride throughout your journey.

</p>

<h3 className=" font-bold text-[16px] md:text-[20px] uppercase mt-5">
PROFESSIONAL AND RELIABLE DRIVERS:
</h3>
<p className=" pt-2">
Our experienced drivers are trained to prioritize your safety and comfort. They are familiar with the best routes between Coimbatore and Trichy, ensuring a smooth and pleasant travel experience

</p>

<h3 className=" font-bold text-[16px] md:text-[20px] uppercase mt-5">
TRANSPARENT PRICING:
</h3>
<p className=" pt-2">
We believe in transparent pricing with no hidden charges. You'll receive a fair and upfront fare estimate before booking your ride, allowing you to plan your travel budget effectively.

</p>

<div className=" my-4 sm:my-6 md:my-10">
<CarsChargeTable />
</div>

<h3 className=" font-bold text-[16px] md:text-[20px] uppercase mt-5">
EASY BOOKING
</h3>
<p className=" pt-2">
Booking your Coimbatore to Trichy taxi is convenient. You can book through our website, mobile app, or by contacting our customer service team. Provide your travel details, and we'll handle the rest.

</p>

<h3 className=" font-bold text-[16px] md:text-[20px] uppercase mt-5">
The 24/7 CUSTOMER SUPPORT:
</h3>
<p className=" pt-2">
Whether you need a taxi during the day or night, our service operates 24/7 to accommodate your travel needs. You can rely on us for a comfortable and reliable ride at any time.

</p>
<h3 className=" font-bold text-[16px] md:text-[20px] uppercase mt-5">
THE ENCHANTING JOURNEY FROM COIMBATORE TO TRICHY:
</h3>

<p className=" pt-5">
Make your journey from Coimbatore to Trichy a hassle-free experience by choosing our reliable taxi service. Sit back, relax, and enjoy the ride as we take you to your destination safely and comfortably.

</p>
<p className=" pt-5">
Contact us today to book your taxi and experience seamless travel with exceptional service. We look forward to serving you and ensuring your trip is memorable for all the right reasons.

</p>
</div>
< PopularOutstations />
    </div>
  )
}

export default CoimbatoretoTrichy
