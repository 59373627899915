import React, { useEffect } from 'react'
import Form from '../Form';
import PopularOutstations from '../PopularOutstations';
import Coimbatore from '../../assets/places/Coimbatore.jpg'
import Bangalore from '../../assets/places/Bangalore.jpg'
import Salem from '../../assets/places/salem.jpeg'
import Madurai from '../../assets/places/Madurai.jpg'
import Pondicherry from '../../assets/places/Pondicherry.jpg'
import Vellore from '../../assets/places/Vellore.jpeg'
import Trichy from '../../assets/places/Trichy.jpg'
import Kumbakonam from '../../assets/places/Kumbakonam.jpeg'
import Thiruvanamalai from '../../assets/places/Thiruvannamali.jpeg'
import { Link } from "react-router-dom";

const Chennai = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    return (
        <div>
            <div className="form_bg relative bg-fixed flex justify-center px-3">
                <div className='bg-white w-full md:w-[80%] lg:w-1/2  rounded-lg px-4  pb-4 relative z-10 mt-4 md:mt-8 mb-4 md:mb-8' id='book_taxi_form'>
                    <Form />
                </div>
            </div>
            <div className=" grid grid-cols-1 mb-5 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 gap-x-3 gap-y-6 sm:gap-y-14 md:gap-y-16 mt-5 sm:mt-5 md:mt-7 lg:mt-10">
                <div className="flex flex-col gap-[10px] items-center">
                    <Link to="/one-way-drop-taxi-chennai-to-coimbatore">
                        <img src={Coimbatore} className="w-[309px] h-[309px]" alt="chennai-to-coimbatore" />
                    </Link>

                    <h1 className="primary-color text-[23px] ">
                        <Link to="/one-way-drop-taxi-chennai-to-coimbatore">Chennai to Coimbatore</Link>
                    </h1>
                    <h6>One Way Taxi</h6>
                    <a href="#book_taxi_form" className='bg-black text-white p-2 px-4 text-align:center'>Book Now</a>
                </div>
                <div className="flex flex-col gap-[10px] items-center">
                    <Link to="/one-way-drop-taxi-chennai-to-bangalore">
                        <img src={Bangalore} className="w-[309px] h-[309px]" alt="chennai-to-bangalore" />
                    </Link>
                    <h1 className="primary-color text-[23px] ">
                        <Link to="/one-way-drop-taxi-chennai-to-bangalore">Chennai to Bangalore</Link>
                    </h1>
                    <h6>One Way Taxi</h6>
                    <a href="#book_taxi_form" className='bg-black text-white p-2 px-4 text-align:center'>Book Now</a>
                </div>
                <div className="flex flex-col gap-[10px] items-center">
                    <Link to="/one-way-drop-taxi-chennai-to-salem">
                        <img src={Salem} className="w-[309px] h-[309px]" alt="chennai-to-salem" />
                    </Link>
                    <h1 className="primary-color text-[23px] ">
                        <Link to="/one-way-drop-taxi-chennai-to-salem">Chennai to Salem</Link>
                    </h1>
                    <h6>One Way Taxi</h6>
                    <a href="#book_taxi_form" className='bg-black text-white p-2  px-4 text-align:center'>Book Now</a>
                </div>
                <div className="flex flex-col gap-[10px] items-center">
                    <Link to="/one-way-drop-taxi-chennai-to-madurai" alt="chennai-to-madurai">
                        <img src={Madurai} className="w-[309px] h-[309px]" />
                    </Link>
                    <h1 className="primary-color text-[23px] ">
                        <Link to="/one-way-drop-taxi-chennai-to-madurai">Chennai to Madurai</Link>
                    </h1>
                    <h6>One Way Taxi</h6>
                    <a href="#book_taxi_form" className='bg-black text-white p-2 px-4 text-align:center'>Book Now</a>
                </div>
                <div className="flex flex-col gap-[10px] items-center">
                    <Link to="/one-way-drop-taxi-chennai-to-pondicherry">
                        <img src={Pondicherry} className="w-[309px] h-[309px]" alt="chennai-to-pondicherry" />
                    </Link>
                    <h1 className="primary-color text-[23px] ">
                        <Link to="/one-way-drop-taxi-chennai-to-pondicherry">Chennai to Pondicherry</Link>
                    </h1>
                    <h6>One Way Taxi</h6>
                    <a href="#book_taxi_form" className='bg-black text-white p-2 px-4 text-align:center'>Book Now</a>
                </div>
                <div className="flex flex-col gap-[10px] items-center">
                    <Link to="/one-way-drop-taxi-chennai-to-vellore">
                        <img src={Vellore} className="w-[309px] h-[309px]" alt="chennai-to-vellore" />
                    </Link>
                    <h1 className="primary-color text-[23px] ">
                        <Link to="/one-way-drop-taxi-chennai-to-vellore">Chennai to Vellore</Link>
                    </h1>
                    <h6>One Way Taxi</h6>
                    <a href="#book_taxi_form" className='bg-black text-white p-2 px-4 text-align:center'>Book Now</a>
                </div>
                <div className="flex flex-col gap-[10px] items-center">
                    <Link to="/one-way-drop-taxi-chennai-to-trichy" alt="chennai-to-trichy">
                        <img src={Trichy} className="w-[309px] h-[309px]" />
                    </Link>
                    <h1 className="primary-color text-[23px] ">
                        <Link to="/one-way-drop-taxi-chennai-to-trichy">Chennai to Trichy</Link>
                    </h1>
                    <h6>One Way Taxi</h6>
                    <a href="#book_taxi_form" className='bg-black text-white p-2  px-4 text-align:center'>Book Now</a>
                </div>
                <div className="flex flex-col gap-[10px] items-center">
                    <Link to="/one-way-drop-taxi-chennai-to-kumbakonam">
                        <img src={Kumbakonam} className="w-[309px] h-[309px]" alt="chennai-to-kumbakonam" />
                    </Link>
                    <h1 className="primary-color text-[23px] ">
                        <Link to="/one-way-drop-taxi-chennai-to-kumbakonam">Chennai to Kumbakonam</Link>

                    </h1>
                    <h6>One Way Taxi</h6>
                    <a href="#book_taxi_form" className='bg-black text-white p-2 px-4 text-align:center'>Book Now</a>
                </div>

                <div className="flex flex-col gap-[10px] items-center">
                    <img src={Thiruvanamalai} className="w-[309px] h-[309px]" />
                    <h1 className="primary-color text-[23px] ">Chennai To Thiruvanamalai</h1>
                    <h6>One Way Taxi</h6>
                    <a href="#book_taxi_form" className='bg-black text-white p-2 px-4 text-align:center'>Book Now</a>
                </div>

            </div>
            < PopularOutstations />

        </div>
    )
}

export default Chennai
