import React from 'react'
import about from "../assets/about.jpg";
import { FaPhone } from 'react-icons/fa6';
const About = () => {
    return (
        <div  id='about'>
            <div className='container py-5 mx-auto px-4 md:px-8 lg:px-16'>
                <div className='block md:flex py-5 gap-x-20 items-center'>
                    <div className='w-full md:w-1/2'>
                        <div className=' relative'>
                            <img src={about} alt="about" className='mx-auto w-[500px] car_outline' />
                        </div>
                    </div>
                    <div className='mt-10 md:mt-0 px-3 w-full md:w-1/2'>
                        <h4 className=' text-lg font-medium primary-color'>About Us</h4>
                        <div className='text-3xl md:text-4xl font-bold mt-4'>Welcome to Covai Best Cabs - Your Trusted Taxi Service in Coimbatore</div>
                        <p className='text-black opacity-60 md:text-lg mt-5'>At Covai Best Cabs, we take pride in being Coimbatore’s most reliable and customer-focused taxi service provider. Whether you need a ride across town, a drop to the airport, or a comfortable vehicle for a long journey, we’ve got you covered. Our fleet of well-maintained cars and professional drivers ensures you enjoy a safe, smooth, and hassle-free ride every time.</p>
                        <div className=' mt-5'>
                        <a
                href="tel:+919385357886"
                className=" rounded-md th-btn-reverse hover:bg-black transition-all ease-linear px-3 py-2"
              >
                <span className=" flex gap-x-2 items-center justify-center">
                  <FaPhone size={17} /> +91 9385357886
                </span>
              </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About