import React from 'react'
import ooty from "../../assets/images/Domestic_packages/ooty-tourp.jpg"
import Kodaikanal from "../../assets/images/Domestic_packages/kodaikanal-toursp.jpg"
import munnar from "../../assets/images/Domestic_packages/munnar-tourp.jpg"
import wayanad from "../../assets/images/Domestic_packages/wayanad-toursp.jpg"
import mudumalai from "../../assets/images/Domestic_packages/mudumalai-toursp.jpg"
import topslip from "../../assets/images/Domestic_packages/topslip-toursp.jpg"
import banner from '../../assets/images/servbg.png'
const Packages = () => {
    const divStyle = {
        backgroundImage: `url(${banner})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        width: "100%",
      };
    
  return (
    <div className='serv_parent' style={divStyle}>

    <div className="container py-5 md:py-8 lg:py-10 mx-auto px-4 md:px-8 lg:px-16" id="tourpackages">
    <div className="text-3xl md:text-4xl font-bold mt-4 text-center text-white">
   Popular Tour Packages    </div>


      <div className='grid grid-cols-1  md:grid-cols-3 lg:grid-cols-3 gap-4  mt-2 pt-5'>
        <div className='flex flex-col '>
                <img src={ooty}/>
           <div className="flex gap-x-2 justify-center">
                 <a  href="#book_taxi_form" className='th-btn mt-3'>BOOK NOW</a>
                 <a  href="tel:+919385357886" className='th-btn-reverse mt-3'>CALL NOW</a>
           </div>
        </div>
        <div className='flex flex-col '>
                <img src={Kodaikanal}/>
           <div className="flex gap-x-2 justify-center">
                 <a  href="#book_taxi_form" className='th-btn mt-3'>BOOK NOW</a>
                 <a  href="tel:+919385357886" className='th-btn-reverse mt-3'>CALL NOW</a>
           </div>
        </div>
        <div className='flex flex-col '>
                <img src={munnar}/>
           <div className="flex gap-x-2 justify-center">
                 <a  href="#book_taxi_form" className='th-btn mt-3'>BOOK NOW</a>
                 <a  href="tel:+919385357886" className='th-btn-reverse mt-3'>CALL NOW</a>
           </div>
        </div>
        <div className='flex flex-col '>
                <img src={wayanad}/>
           <div className="flex gap-x-2 justify-center">
                 <a  href="#book_taxi_form" className='th-btn mt-3'>BOOK NOW</a>
                 <a  href="tel:+919385357886" className='th-btn-reverse mt-3'>CALL NOW</a>
           </div>
        </div>
        <div className='flex flex-col '>
                <img src={mudumalai}/>
           <div className="flex gap-x-2 justify-center">
                 <a  href="#book_taxi_form" className='th-btn mt-3'>BOOK NOW</a>
                 <a  href="tel:+919385357886" className='th-btn-reverse mt-3'>CALL NOW</a>
           </div>
        </div>
        <div className='flex flex-col '>
                <img src={topslip}/>
           <div className="flex gap-x-2 justify-center">
                 <a  href="#book_taxi_form" className='th-btn mt-3'>BOOK NOW</a>
                 <a  href="tel:+919385357886" className='th-btn-reverse mt-3'>CALL NOW</a>
           </div>
        </div>

      </div>
   </div>

    </div>
  )
}

export default Packages