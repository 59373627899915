import React, { useEffect } from 'react'
import GoogleMapPoint from '../../GoogleMapPoint'
import Form from '../../Form'
import CarsChargeTable from '../Common/CarsChargeTable'
import PopularOutstations from '../../PopularOutstations'

const TrichytoSalem = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

  return (
<div>
<GoogleMapPoint origin={{lat:10.7905,lng:78.7047}} destination={{lat:11.6643,lng:78.1460}}/>

<div className="form_bg relative bg-fixed flex justify-center px-3">
<div className='bg-white w-full md:w-[80%] lg:w-1/2  rounded-lg px-4  pb-4 relative z-10 mt-4 md:mt-8 mb-4 md:mb-8' id='book_taxi_form'>
<Form />
</div>
</div>


<div className=" container px-3 sm:px-4 md:px-5 lg:px-10 text-[#082a5e] py-4">
<h3 className=" font-bold text-[26px] sm:text-[28px] md:text-[30px] uppercase">
CONVENIENT TRICHY TO SALEM TAXI SERVICE: SMOOTH AND RELIABLE TRAVEL EXPERIENCE
</h3>
<p className=" pt-2">
Are you planning a trip from Trichy to Salem and searching for a convenient and reliable taxi service? Look no further! Our taxi service offers a seamless and comfortable travel solution tailored to make your journey between these two vibrant cities hassle-free and enjoyable.

</p>

<h3 className=" font-bold text-[26px] sm:text-[28px] md:text-[30px] uppercase mt-5">
DOORSTEP PICKUP AND DROP-OFF:
</h3>
<p className=" pt-2">
Punctuality is our commitment. Our drivers will arrive promptly at your specified location in Trichy and ensure you reach Salem on time, without any unnecessary delays.

</p>

<h3 className=" font-bold text-[16px] md:text-[20px] uppercase mt-5">
COMFORTABLE AND WELL-MAINTAINED VEHICLES:
</h3>
<p className=" pt-2">
Outstation Taxi understands the value of convenience, and hence, they
offer doorstep pickup and drop-off services. Whether you're starting
your journey from Coimbatore Central or planning to board the taxi
near the airport, Outstation Taxi ensures that your travel begins and
ends right at your desired location.
</p>

<h3 className=" font-bold text-[16px] md:text-[20px] uppercase mt-5">
Comfortable and Well-Maintained Vehicles:
</h3>
<p className=" pt-2">
Choose from our fleet of well-maintained cars, including sedans, SUVs, and spacious vehicles suitable for families or groups. Experience a comfortable and relaxing ride throughout your journey.

</p>

<h3 className=" font-bold text-[16px] md:text-[20px] uppercase mt-5">
PROFESSIONAL AND RELIABLE DRIVERS:
</h3>
<p className=" pt-2">
Our experienced drivers prioritize your safety and comfort. They are familiar with the best routes between Trichy and Salem, ensuring a smooth and efficient travel experience.

</p>

<h3 className=" font-bold text-[16px] md:text-[20px] uppercase mt-5">
TRANSPARENT PRICING:
</h3>
<p className=" pt-2">
We believe in transparent pricing with no hidden charges. You'll receive a fair and upfront fare estimate before booking your ride, allowing you to plan your travel budget effectively.

</p>

<div className=" my-4 sm:my-6 md:my-10">
<CarsChargeTable />
</div>

<h3 className=" font-bold text-[16px] md:text-[20px] uppercase mt-5">
CONVENIENT BOOKING:
</h3>
<p className=" pt-2">
Booking your Trichy to Salem taxi is easy. You can book through our website, mobile app, or by contacting our customer service team. Provide your travel details, and we'll handle the rest.

</p>

<h3 className=" font-bold text-[16px] md:text-[20px] uppercase mt-5">
24/7 CUSTOMER SUPPORT:
</h3>
<p className=" pt-2">
Whether you need a taxi during the day or night, our service operates 24/7 to accommodate your travel needs. You can rely on us for a comfortable and reliable ride at any time.

</p>
<h3 className=" font-bold text-[16px] md:text-[20px] uppercase mt-5">
THE ENCHANTING JOURNEY FROM TRICHY TO SALEM:
</h3>

<p className=" pt-5">
Make your journey from Trichy to Salem stress-free and enjoyable by choosing our reliable taxi service. Sit back, relax, and enjoy the scenic landscapes as we take you to your destination safely and comfortably.

</p>
<p className=" pt-5">
Contact us today to book your taxi and experience convenient travel with exceptional service. We look forward to serving you and ensuring your trip to Salem is memorable for all the right reasons.

</p>
</div>
< PopularOutstations />

    </div>  )
}

export default TrichytoSalem
