import React, { useEffect } from 'react'
import GoogleMapPoint from '../../GoogleMapPoint'
import Form from '../../Form'
import CarsChargeTable from '../Common/CarsChargeTable'
import PopularOutstations from '../../PopularOutstations'

const SalemtoPondicherry = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

  return (
<div>
<GoogleMapPoint origin={{lat:11.6643,lng:78.1460}} destination={{lat:11.9416,lng:79.8083}}/>

<div className="form_bg relative bg-fixed flex justify-center px-3">
<div className='bg-white w-full md:w-[80%] lg:w-1/2  rounded-lg px-4  pb-4 relative z-10 mt-4 md:mt-8 mb-4 md:mb-8' id='book_taxi_form'>
<Form />
</div>
</div>


<div className=" container px-3 sm:px-4 md:px-5 lg:px-10 text-[#082a5e] py-4">
<h3 className=" font-bold text-[26px] sm:text-[28px] md:text-[30px] uppercase">
SEAMLESS SALEM TO PONDICHERRY TAXI SERVICE: YOUR GATEWAY TO COASTAL TRANQUILITY
</h3>
<p className=" pt-2">
Embark on a seamless and comfortable journey from Salem to Pondicherry with our reliable taxi service. Our goal is to provide you with a stress-free travel experience, ensuring your trip is safe, timely, and enjoyable.

</p>

<h3 className=" font-bold text-[26px] sm:text-[28px] md:text-[30px] uppercase mt-5">
DOORSTEP PICKUP AND DROP-OFF:
</h3>
<p className=" pt-2">
Punctuality is our commitment. Our drivers will arrive promptly at your specified location in Salem and ensure you reach Pondicherry on time, without any unnecessary delays.

</p>

<h3 className=" font-bold text-[16px] md:text-[20px] uppercase mt-5">
COMFORTABLE AND WELL-MAINTAINED VEHICLES:
</h3>
<p className=" pt-2">
Choose from a fleet of well-maintained cars, including sedans, SUVs, and spacious vehicles suitable for families or groups. Our vehicles are equipped with modern amenities to ensure a comfortable ride throughout your journey.

</p>

<h3 className=" font-bold text-[16px] md:text-[20px] uppercase mt-5">
PROFESSIONAL AND RELIABLE DRIVERS:
</h3>
<p className=" pt-2">
Our experienced drivers prioritize your safety and comfort. They are familiar with the best routes between Salem and Pondicherry, ensuring a smooth and efficient travel experience.

</p>

<h3 className=" font-bold text-[16px] md:text-[20px] uppercase mt-5">
TRANSPARENT PRICING:
</h3>
<p className=" pt-2">
We believe in transparent pricing with no hidden charges. You'll receive a fair and upfront fare estimate before booking your ride, allowing you to plan your travel budget effectively

</p>

<h3 className=" font-bold text-[16px] md:text-[20px] uppercase mt-5">
CONVENIENT BOOKING:
</h3>
<p className=" pt-2">
Booking your Salem to Pondicherry taxi is easy. You can book through our website, mobile app, or by contacting our customer service team. Provide your travel details, and we'll handle the rest.

</p>

<div className=" my-4 sm:my-6 md:my-10">
<CarsChargeTable />
</div>

<h3 className=" font-bold text-[16px] md:text-[20px] uppercase mt-5">
24/7 Customer Support:
</h3>
<p className=" pt-2">
Whether you need a taxi during the day or night, our service operates 24/7 to accommodate your travel needs. You can rely on us for a comfortable and reliable ride at any time.

</p>

<h3 className=" font-bold text-[16px] md:text-[20px] uppercase mt-5">
The Enchanting Journey from Coimbatore to Bangalore:
</h3>
<p className=" pt-2">
Make your journey from Salem to Pondicherry a memorable one with our reliable taxi service. Sit back, relax, and enjoy the scenic coastal views as we take you to your destination safely and comfortably.

</p>

<p className=" pt-5">
Contact us today to book your taxi and experience seamless travel with exceptional service. We look forward to serving you and ensuring your trip to Pondicherry is filled with wonderful memories.

</p>
</div>
< PopularOutstations />

    </div>  )
}

export default SalemtoPondicherry
