import React from "react";
import sedan from "../../assets/images/cars/sedan.png";
import etios from "../../assets/images/cars/etios.png";
import suv from "../../assets/images/cars/suv.png";
import innova from "../../assets/images/cars/innova.png";
import tempo from "../../assets/images/cars/tempo.webp";
import PricePlanComponents from "./PricePlanComponents";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const PricePlan = () => {
  const settings = {
    dots: false, // Hides the bottom navigation dots
    infinite: true,
    speed: 800,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false, // Hides the arrow buttons
    autoplay: true, // Enables autoplay
    autoplaySpeed: 2000, // Sets the autoplay speed in milliseconds
    responsive: [
      {
        breakpoint: 1024, // Breakpoint for desktops
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 768, // Breakpoint for tablets
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 480, // Breakpoint for mobile devices
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };
  return (
    <div className=" bg-gray-200">
      <div
        className="container py-5 md:py-8 lg:py-10 mx-auto px-4 md:px-8 lg:px-16"
        id="tarrif"
      >
        <h4 className=" text-lg font-medium primary-color text-center">
          Pricing Plan
        </h4>
        <div className="text-3xl md:text-4xl font-bold mt-4 text-center">
          Our Most Valuable Drop Taxi Pricing
        </div>
        {/* <div className=" grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4  2xl:grid-cols-5 gap-3 mt-5 sm:mt-6 md:mt-8 lg:mt-10"> */}
      <div className="mt-5 sm:mt-6 md:mt-8 lg:mt-10 price_plan">
        <Slider {...settings}>
          <PricePlanComponents
            place="SEDAN"
            image={sedan}
          
          />
          <PricePlanComponents
            place="ETIOS"
            image={etios}
          
          />
          <PricePlanComponents
            place="SUV"
            image={suv}
     
          />
          <PricePlanComponents
            place="INNOVA"
            image={innova}
           
          />
        </Slider>
        </div>
      </div>
    </div>
  );
};

export default PricePlan;
