import React, { useEffect, useState } from "react";
// import axios from "axios";

import {
  GoogleMap,
  DirectionsRenderer,
  Marker,
} from "@react-google-maps/api";

const GoogleMapPoint = ({ origin, destination }) => {
  const [directions, setDirections] = useState(null);
  useEffect(() => {
    const directionsService = new window.google.maps.DirectionsService();

    directionsService.route(
      {
        origin: { lat: origin?.lat, lng: origin?.lng }, // Chennai
        destination: { lat: destination?.lat, lng: destination?.lng }, // Coimbatore
        travelMode: "DRIVING",
      },
      (result, status) => {
        if (status === "OK") {
          setDirections(result);
        } else {
          console.error( `Error fetching directions ${result}` );
        }
      }
    );
  }, []);
  //   const fetchLocationNames = async () => {
  //     const originNameResponse = await fetchLocationName(origin);
  //     const destinationNameResponse = await fetchLocationName(destination);

  //     setOriginName(originNameResponse);
  //     setDestinationName(destinationNameResponse);
  //   };
  // }
  //   fetchDirections();
  //   fetchLocationName();
  // }
  

    // const geocoder = new window.google.maps.Geocoder();

    // geocoder.geocode({ location: latLng }, (results, status) => {
    //   if (status === "OK" && results?.[0]) {
    //     setSelectedPlaceName(results[0].formatted_address);
    //   } else {
    //     console.error(`Error fetching place details: ${status}`);
    //   }
    // });    
    // const fetchLocationName = async (location) => {
    //   try {
    //     const response = await axios.get(
    //       `https://maps.googleapis.com/maps/api/geocode/json?latlng=${location.lat},${location.lng}&key=YOUR_API_KEY`
    //     );
  
    //     if (response.data && response.data.results && response.data.results.length > 0) {
    //       return response.data.results[0].formatted_address;
    //     } else {
    //       console.error("No location name found");
    //       return "";
    //     }
    //   } catch (error) {
    //     console.error("Error fetching location name:", error);
    //     return "";
    //   }
    // };
   
  

  return (
    <div>
      <GoogleMap
        mapContainerStyle={{ width: "100%", height: "400px" }}
        center={{ lat: origin?.lat, lng: origin?.lng }} // Chennai
        zoom={7}
      >
        <Marker position={{ lat: origin?.lat, lng: origin?.lng }} label="A" />
        <Marker position={{ lat: destination?.lat, lng: destination?.lng }} label="B" />
        {directions && <DirectionsRenderer directions={directions}  />}
      </GoogleMap>
      {/* <div>
      <GoogleMapPoint originName={originName} />
        <p>Destination: {destinationName}</p>
      </div> */}

    </div>
  );
};

export default GoogleMapPoint;
