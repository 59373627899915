import React, { useEffect } from 'react'
import GoogleMapPoint from '../../GoogleMapPoint'
import Form from '../../Form'
import CarsChargeTable from '../Common/CarsChargeTable'
import PopularOutstations from '../../PopularOutstations'

const TrichytoCoimbatore = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

  return (
<div>
<GoogleMapPoint origin={{lat:10.7905,lng:78.7047}} destination={{lat:11.0168,lng:76.9558}}/>

<div className="form_bg relative bg-fixed flex justify-center px-3">
<div className='bg-white w-full md:w-[80%] lg:w-1/2  rounded-lg px-4  pb-4 relative z-10 mt-4 md:mt-8 mb-4 md:mb-8' id='book_taxi_form'>
<Form />
</div>
</div>


<div className=" container px-3 sm:px-4 md:px-5 lg:px-10 text-[#082a5e] py-4">
<h3 className=" font-bold text-[26px] sm:text-[28px] md:text-[30px] uppercase">
TRICHY TO COIMBATORE TAXI SERVICE: COMFORTABLE AND CONVENIENT TRAVEL EXPERIENCE"
</h3>
<p className=" pt-2">
Embark on a comfortable and convenient journey from Trichy to Coimbatore with our reliable taxi service. Our goal is to provide you with a seamless travel experience, ensuring your trip is enjoyable and stress-free.

</p>

<h3 className=" font-bold text-[26px] sm:text-[28px] md:text-[30px] uppercase mt-5">
DOORSTEP PICKUP AND DROP-OFF:
    </h3>
<p className=" pt-2">
We understand the importance of punctuality. Our drivers will arrive promptly at your specified location in Trichy and ensure you reach Coimbatore on time, without any unnecessary delays.

</p>

<h3 className=" font-bold text-[16px] md:text-[20px] uppercase mt-5">
COMFORTABLE AND WELL-MAINTAINED VEHICLES:
</h3>
<p className=" pt-2">
Choose from a fleet of well-maintained cars, including sedans, SUVs, and spacious vehicles suitable for groups. Our vehicles are designed to provide a comfortable ride throughout your journey.

</p>

<h3 className=" font-bold text-[16px] md:text-[20px] uppercase mt-5">
PROFESSIONAL AND RELIABLE DRIVERS:
</h3>
<p className=" pt-2">
Choose from a fleet of well-maintained cars, including sedans, SUVs, and spacious vehicles suitable for groups. Our vehicles are designed to provide a comfortable ride throughout your journey.

</p>

<h3 className=" font-bold text-[16px] md:text-[20px] uppercase mt-5">
TRANSPARENT PRICING:
</h3>
<p className=" pt-2">
We believe in transparent pricing with no hidden charges. You'll receive a fair and upfront fare estimate before booking your ride, allowing you to plan your travel budget effectively

</p>

<h3 className=" font-bold text-[16px] md:text-[20px] uppercase mt-5">
CONVENIENT BOOKING:
</h3>
<p className=" pt-2">
Booking your Trichy to Coimbatore taxi is easy. You can book online through our website, mobile app, or by contacting our customer service team. Provide your travel details, and we'll handle the rest.

</p>

<div className=" my-4 sm:my-6 md:my-10">
<CarsChargeTable />
</div>

<h3 className=" font-bold text-[16px] md:text-[20px] uppercase mt-5">
24/7 Customer Support:
</h3>
<p className=" pt-2">
Whether you need a taxi during the day or night, our services are available 24/7 to accommodate your travel needs. You can rely on us for a comfortable and reliable ride at any time.

</p>

<h3 className=" font-bold text-[16px] md:text-[20px] uppercase mt-5">
THE ENCHANTING JOURNEY FROM TRICHY TO COIMBATORE:
</h3>
<p className=" pt-2">
Make your journey from Trichy to Coimbatore hassle-free and enjoyable with our reliable taxi service. Sit back, relax, and enjoy the scenic landscapes along the way.

</p>

<p className=" pt-5">
Contact us today to book your taxi and experience a comfortable and convenient travel experience. We look forward to serving you and ensuring your trip to Coimbatore is memorable for all the right reasons.


</p>
</div>
< PopularOutstations />

    </div>  )
}

export default TrichytoCoimbatore
