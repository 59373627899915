import React from "react";
import img1 from "../../assets/images/ser1.png";
import img2 from "../../assets/images/ser2.png";
import img3 from "../../assets/images/ser3.png";
import img4 from "../../assets/images/ser4.png";
const OurServices = () => {
  return (
    <div>
      <div className="container py-5 mx-auto px-4 md:px-8 lg:px-16">
        <h4 className="text-[#241D92] text-lg font-medium primary-color text-center">
          Our Service
        </h4>
        
        <div className="flex flex-col gap-y-[10px] pt-4">
          <p>
           <span className="font-bold pr-2">Local Rides</span>
           Need to get around Coimbatore? We offer convenient local taxi services for daily commutes, errands, and special occasions.
          </p>
          <p>
           <span className="font-bold pr-2">Airport Transfers</span>
           Stress-free transfers to and from Coimbatore International Airport. Arrive on time, every time.
                     </p>
                     <p>
           <span className="font-bold pr-2">Outstation Trips</span>
           Planning a trip outside Coimbatore? Our outstation taxi services are perfect for long journeys, with options for one-way or round trips.                     </p>
           <p>
           <span className="font-bold pr-2">Corporate Travel</span>
           Reliable and professional taxi services tailored for business needs. We ensure your employees and clients travel in comfort and style.                     </p>
          
        </div>
      </div>
      <div className="  px-0 sm:px-4 md:px-8 lg:px-16 bg-[#f5f5f5]  py-3 md:py-5 mt-4 md:mt-6 lg:mt-8">
        <div className="container grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-2  text-center">
          <div className=" flex flex-col gap-y-1">
            <img src={img1} className=" w-[80px] mx-auto" />
            <p className=" text-lg md:text-xl font-semibold">
              Easy Online Booking
            </p>
            <p>Pickup and Drop on time</p>
          </div>
          <div className=" flex flex-col gap-y-1">
            <img src={img2} className=" w-[80px] mx-auto" />
            <p className=" text-lg md:text-xl font-semibold">
            Professional Drivers
            </p>
            <p>Well Trained years of experience</p>
          </div>
          <div className=" flex flex-col gap-y-1">
            <img src={img3} className=" w-[80px] mx-auto" />
            <p className=" text-lg md:text-xl font-semibold">
            Variety of Prime Brands
            </p>
            <p>Suv, Innova, Sedan, Etios</p>
          </div>
          <div className=" flex flex-col gap-y-1">
            <img src={img4} className=" w-[80px] mx-auto" />
            <p className=" text-lg md:text-xl font-semibold">
            Secure Online Payment
            </p>
            <p>24 /7 Customer Support</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurServices;
