import React from "react";
import { FaCar } from "react-icons/fa";
import { Link } from "react-router-dom";
import bg from "../assets/images/servbg.png";

const PopularOutstations = () => {
  return (
    <div className="bg-[#000]" style={{ backgroundImage: `url(${bg})`}}>
      <div className=" container py-4 md:py-6 lg:py-8 mx-auto px-3 sm:px-4 md:px-8">
        <div className=" text-center">
          <h3 className="text-[23px] md:text-[25px] font-bold text-white relative inline-block header-underline pb-3 before:hidden sm:before:block">
           Covai Best Cabs OUTSTATION CAB SERVICES
          </h3>
        </div>
        <div className=" grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 gap-x-3 gap-y-6 sm:gap-y-14 md:gap-y-16 mt-5 sm:mt-5 md:mt-7 lg:mt-10">
          <div>
            <h3 className="text-[21px] md:text-[23px] font-bold text-white relative inline-block pb-3 before:hidden sm:before:block">
              CHENNAI DROP TAXI
            </h3>
            <ul className=" flex flex-col gap-[5px]">
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                <Link to="/one-way-drop-taxi-chennai-to-coimbatore">Chennai to Coimbatore Drop Taxi</Link>
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                <Link to="/one-way-drop-taxi-chennai-to-bangalore">Chennai to Bangalore Drop Taxi</Link>
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                <Link to="/one-way-drop-taxi-chennai-to-salem">Chennai to Salem Drop Taxi</Link>
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                <Link to="/one-way-drop-taxi-chennai-to-madurai">Chennai to MaduraI Drop Taxis</Link>
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                <Link to="/one-way-drop-taxi-chennai-to-pondicherry">Chennai to Pondicherry Drop Taxi</Link>
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                <Link to="/one-way-drop-taxi-chennai-to-vellore">Chennai to Vellore Drop Taxi</Link>
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                <Link to="/one-way-drop-taxi-chennai-to-trichy">Chennai to Trichy Drop Taxi</Link>
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                <Link to="/one-way-drop-taxi-chennai-to-kumbakonam">Chennai to Kumbakonam Drop Taxi</Link>
              </li>
            </ul>
          </div>
          <div>
            <h3 className="text-[21px] md:text-[23px] font-bold text-white relative inline-block pb-3 before:hidden sm:before:block">
              COIMBATORE DROP TAXI
            </h3>
            <ul className=" flex flex-col gap-[5px]">
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                <Link to="/one-way-drop-taxi-coimbatore-to-chennai">Coimbatore to ChennaI Drop Taxis</Link>
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                <Link to="/one-way-drop-taxi-coimbatore-to-bangalore">Coimbatore to Bangalore Drop Taxi</Link>
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                <Link to="/one-way-drop-taxi-coimbatore-to-salem">Coimbatore to Salem Drop Taxi</Link>
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                <Link to="/one-way-drop-taxi-coimbatore-to-madurai">Coimbatore to MaduraI Drop Taxis</Link>
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                <Link to="/one-way-drop-taxi-coimbatore-to-pondicherry">Coimbatore to Pondicherry Drop Taxi</Link>
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                <Link to="/one-way-drop-taxi-coimbatore-to-vellore">Coimbatore to Vellore Drop Taxi</Link>
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                <Link to="/one-way-drop-taxi-coimbatore-to-trichy">Coimbatore to Trichy Drop Taxi</Link>
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                <Link to="/one-way-drop-taxi-coimbatore-to-kumbakonam">Coimbatore to Kumbakonam Drop Taxi</Link>
              </li>
            </ul>
          </div>
          <div>
            <h3 className="text-[21px] md:text-[23px] font-bold text-white relative inline-block pb-3 before:hidden sm:before:block">
              BANGALORE DROP TAXI
            </h3>
            <ul className=" flex flex-col gap-[5px]">
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                <Link to="/one-way-drop-taxi-bangalore-to-chennai">Bangalore to ChennaI Drop Taxis</Link>
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                <Link to="/one-way-drop-taxi-bangalore-to-coimbatore">Bangalore to Coimbatore Drop Taxi</Link>
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                <Link to="/one-way-drop-taxi-bangalore-to-salem">Bangalore to Salem Drop Taxi</Link>
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                <Link to="/one-way-drop-taxi-bangalore-to-madurai">Bangalore to MaduraI Drop Taxis</Link>
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                <Link to="/one-way-drop-taxi-bangalore-to-pondicherry">Bangalore to Pondicherry Drop Taxi</Link>
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                <Link to="/one-way-drop-taxi-bangalore-to-vellore">Bangalore to Vellore Drop Taxi</Link>
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                <Link to="/one-way-drop-taxi-bangalore-to-trichy">Bangalore to Trichy Drop Taxi</Link>
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                <Link to="/one-way-drop-taxi-bangalore-to-kumbakonam">Bangalore to Kumbakonam Drop Taxi</Link>
              </li>
            </ul>
          </div>
          <div>
            <h3 className="text-[21px] md:text-[23px] font-bold text-white relative inline-block pb-3 before:hidden sm:before:block">
            SALEM DROP TAXI
            </h3>
            <ul className=" flex flex-col gap-[5px]">
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                <Link to="/one-way-drop-taxi-salem-to-chennai">Salem to ChennaI Drop Taxis</Link>
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                <Link to="/one-way-drop-taxi-salem-to-coimbatore">Salem to Coimbatore Drop Taxi</Link>
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                <Link to="/one-way-drop-taxi-salem-to-bangalore">Salem to Bangalore Drop Taxi</Link>
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                <Link to="/one-way-drop-taxi-salem-to-madurai">Salem to MaduraI Drop Taxis</Link>
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                <Link to="/one-way-drop-taxi-salem-to-pondicherry">Salem to Pondicherry Drop Taxi</Link>
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                <Link to="/one-way-drop-taxi-salem-to-vellore">Salem to Vellore Drop Taxi</Link>
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                <Link to="/one-way-drop-taxi-salem-to-trichy">Salem to Trichy Drop Taxi</Link>
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                <Link to="/one-way-drop-taxi-salem-to-kumbakonam">Salem to Kumbakonam Drop Taxi</Link>
              </li>
            </ul>
          </div>
          <div>
            <h3 className="text-[21px] md:text-[23px] font-bold text-white relative inline-block pb-3 before:hidden sm:before:block">
            MADURAI DROP TAXI
            </h3>
            <ul className=" flex flex-col gap-[5px]">
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                <Link to="/one-way-drop-taxi-madurai-to-chennai">Madurai to ChennaI Drop Taxis</Link>
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                <Link to="/one-way-drop-taxi-madurai-to-coimbatore">Madurai to Coimbatore Drop Taxi</Link>
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                <Link to="/one-way-drop-taxi-madurai-to-bangalore">Madurai to Bangalore Drop Taxi</Link>
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                <Link to="/one-way-drop-taxi-madurai-to-salem">Madurai to Salem Drop Taxi</Link>
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                <Link to="/one-way-drop-taxi-madurai-to-pondicherry">Madurai to Pondicherry Drop Taxi</Link>
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                <Link to="/one-way-drop-taxi-madurai-to-vellore">Madurai to Vellore Drop Taxi</Link>
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                <Link to="/one-way-drop-taxi-madurai-to-trichy">Madurai to Trichy Drop Taxi</Link>
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                <Link to="/one-way-drop-taxi-madurai-to-kumbakonam">Madurai to Kumbakonam Drop Taxi</Link>
              </li>
            </ul>
          </div>
          <div>
            <h3 className="text-[21px] md:text-[23px] font-bold text-white relative inline-block pb-3 before:hidden sm:before:block">
            PONDICHERRY DROP TAXI
            </h3>
            <ul className=" flex flex-col gap-[5px]">
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                <Link to="/one-way-drop-taxi-pondicherry-to-chennai">Pondicherry to ChennaI Drop Taxis</Link>
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                <Link to="/one-way-drop-taxi-pondicherry-to-coimbatore">Pondicherry to Coimbatore Drop Taxi</Link>
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                <Link to="/one-way-drop-taxi-pondicherry-to-bangalore">Pondicherry to Bangalore Drop Taxi</Link>
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                <Link to="/one-way-drop-taxi-pondicherry-to-salem">Pondicherry to Salem Drop Taxi</Link>
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                <Link to="/one-way-drop-taxi-pondicherry-to-madurai">Pondicherry to MaduraI Drop Taxis</Link>
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                <Link to="/one-way-drop-taxi-pondicherry-to-vellore">Pondicherry to Vellore Drop Taxi</Link>
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                <Link to="/one-way-drop-taxi-pondicherry-to-trichy">Pondicherry to Trichy Drop Taxi</Link>
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                <Link to="/one-way-drop-taxi-pondicherry-to-kumbakonam">Pondicherry to Kumbakonam Drop Taxi</Link>
              </li>
            </ul>
          </div>
          <div>
            <h3 className="text-[21px] md:text-[23px] font-bold text-white relative inline-block pb-3 before:hidden sm:before:block">
            VELLORE DROP TAXI
            </h3>
            <ul className=" flex flex-col gap-[5px]">
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                <Link to="/one-way-drop-taxi-vellore-to-chennai">Vellore to ChennaI Drop Taxis</Link>
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                <Link to="/one-way-drop-taxi-vellore-to-coimbatore">Vellore to Coimbatore Drop Taxi</Link>
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                <Link to="/one-way-drop-taxi-vellore-to-bangalore">Vellore to Bangalore Drop Taxi</Link>
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                <Link to="/one-way-drop-taxi-vellore-to-salem">Vellore to Salem Drop Taxi</Link>
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                <Link to="/one-way-drop-taxi-vellore-to-madurai">Vellore to MaduraI Drop Taxis</Link>
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                <Link to="/one-way-drop-taxi-vellore-to-pondicherry">Vellore to Pondicherry Drop Taxi</Link>
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                <Link to="/one-way-drop-taxi-vellore-to-trichy">Vellore to Trichy Drop Taxi</Link>
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                <Link to="/one-way-drop-taxi-vellore-to-kumbakonam">Vellore to Kumbakonam Drop Taxi</Link>
              </li>
            </ul>
          </div>
          <div>
            <h3 className="text-[21px] md:text-[23px] font-bold text-white relative inline-block pb-3 before:hidden sm:before:block">
            TRICHY DROP TAXI
            </h3>
            <ul className=" flex flex-col gap-[5px]">
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                <Link to="/one-way-drop-taxi-trichy-to-chennai">Trichy to ChennaI Drop Taxis</Link>
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                <Link to="/one-way-drop-taxi-trichy-to-coimbatore">Trichy to Coimbatore Drop Taxi</Link>
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                <Link to="/one-way-drop-taxi-trichy-to-bangalore">Trichy to Bangalore Drop Taxi</Link>
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                <Link to="/one-way-drop-taxi-trichy-to-salem">Trichy to Salem Drop Taxi</Link>
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                <Link to="/one-way-drop-taxi-trichy-to-madurai">Trichy to MaduraI Drop Taxis</Link>
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                <Link to="/one-way-drop-taxi-trichy-to-pondicherry">Trichy to Pondicherry Drop Taxi</Link>
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                <Link to="/one-way-drop-taxi-trichy-to-vellore">Trichy to Vellore Drop Taxi</Link>
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                <Link to="/one-way-drop-taxi-trichy-to-kumbakonam">Trichy to Kumbakonam Drop Taxi</Link>
              </li>
            </ul>
          </div>
          <div>
            <h3 className="text-[21px] md:text-[23px] font-bold text-white relative inline-block pb-3 before:hidden sm:before:block">
            KUMBAKONAM DROP TAXI
            </h3>
            <ul className=" flex flex-col gap-[5px]">
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                <Link to="/one-way-drop-taxi-kumbakonam-to-chennai">Kumbakonam to ChennaI Drop Taxis</Link>
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                <Link to="/one-way-drop-taxi-kumbakonam-to-coimbatore">Kumbakonam to Coimbatore Drop Taxi</Link>
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                <Link to="/one-way-drop-taxi-kumbakonam-to-bangalore">Kumbakonam to Bangalore Drop Taxi</Link>
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                <Link to="/one-way-drop-taxi-kumbakonam-to-salem">Kumbakonam to Salem Drop Taxi</Link>
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                <Link to="/one-way-drop-taxi-kumbakonam-to-madurai">Kumbakonam to MaduraI Drop Taxis</Link>
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                <Link to="/one-way-drop-taxi-kumbakonam-to-pondicherry">Kumbakonam to Pondicherry Drop Taxi</Link>
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                <Link to="/one-way-drop-taxi-kumbakonam-to-vellore">Kumbakonam to Vellore Drop Taxi</Link>
              </li>
              <li className=" flex gap-x-2 text-lg items-center text-[#D2C5C5]">
                <FaCar className=" text-[20px] text-[#ffc503]" />
                <Link to="/one-way-drop-taxi-kumbakonam-to-trichy">Kumbakonam to Trichy Drop Taxi</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopularOutstations;
